import axios from "axios";
const ENDPOINT = "/api/v1/locations";

const get = async payload => {

  return axios.get(ENDPOINT + '/school-profile', { params: payload });
};


const update = async payload => {
  return await axios.put(ENDPOINT + '/school-profile', payload);
};



export { get, update };
